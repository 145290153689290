import { useFilterState } from '@/context'
import { Category } from '@/types/blocks'
import { FC, useEffect, useLayoutEffect, useState } from 'react'
import { AnimatedTitle } from '../AnimatedTitle'
import { Button } from '../Button'
import { Container } from '../Container'
import Icon from '../Icon'
import { ShortText } from '../Inputs/ShortText'
import { motion, useInView, Variants } from 'framer-motion'
import { useWindowSize } from '@/hooks/useWindowSize'
import cx from 'classnames'

interface INewsFiltersProps {
	categories: Category[]
}

export const NewsFilters: FC<INewsFiltersProps> = ({ categories = [] }) => {
	const { filters, setFilters } = useFilterState()
	const [width, height] = useWindowSize()
	const [categoryOpen, setCategoryOpen] = useState<boolean>(false)
	const [mobileFilter, setMobileFilter] = useState<boolean>(false)
	const [isMobile, setIsMobile] = useState<boolean>(false)

	useLayoutEffect(() => {
		if (width < 768) setIsMobile(true)
		else setIsMobile(false)

		return () => setIsMobile(false)
	}, [width])

	const variants: Variants = {
		open: {
			opacity: 1,
		},
		closed: {
			opacity: 0,
			transition: {
				duration: 0,
			},
		},
	}

	const container = {
		open: {
			height: `100%`,
			transition: {
				height: {
					stiffness: 0,
					velocity: -100,
				},
				staggerChildren: 0.2,
				staggerDirection: 0.3,
			},
		},
		closed: {
			height: `0px`,
			transition: {
				height: {
					stiffness: 0,
				},
				staggerChildren: 0,
				delayChildren: 0,
			},
		},
	}

	const handleClick = () => setMobileFilter(!mobileFilter)

	const handleCategory = (categoryName) => {
		if (filters.categories.includes(categoryName)) {
			setFilters((prevState) => ({
				...prevState,
				categories: filters.categories.filter((value) => value !== categoryName),
			}))
		} else {
			setFilters((prevState) => ({
				...prevState,
				categories: [...filters.categories, categoryName],
			}))
		}
	}

	return (
		<div className="py-6 bg-white sticky top-16 z-50">
			<Container
				className={`flex md:flex-row md:justify-between items-center w-full flex-col ${
					mobileFilter ? `gap-4` : `gap-0`
				}`}
			>
				<span
					className="flex flex-row flex-nowrap items-center justfiy-center md:justify-start cursor-pointer relative"
					onClick={handleClick}
				>
					<AnimatedTitle.Small
						animation="opacity"
						variant="span"
						className="pointer-events-none"
					>
						Filter by
					</AnimatedTitle.Small>

					<Icon
						name="chevronRight"
						className={`w-[16px] h-[16px] md:hidden absolute -right-6 ${
							mobileFilter ? `rotate-90` : `rotate-0`
						} transition-transform`}
					/>
				</span>
				<div
					className={`flex flex-col md:flex-row flex-nowrap max-w-[600px] gap-4 w-full md:flex ${
						mobileFilter ? `flex` : `hidden`
					}`}
				>
					<ShortText
						{...{
							onChange: (e) =>
								setFilters((prevState) => ({
									...prevState,
									keywords: e,
								})),
							placeholder: `Search Keyword`,
							value: filters?.keywords || ``,
							className: `rounded-lg lg:w-[275px]`,
							required: false,
						}}
					/>
					<div
						className="bg-gradient-sky py-4 px-8 rounded-lg relative cursor-pointer"
						onClick={() => setCategoryOpen(!categoryOpen)}
					>
						<span className="font-nexa font-bold text-white flex flex-row flex-nowrap gap-4 items-center justify-between">
							Category{` `}
							<Icon
								name="chevronRight"
								className={`max-w-[15px] fill-white transition-transform duration-300 ${
									categoryOpen ? `rotate-90` : `rotate-0`
								}`}
							/>
						</span>
						<div
							className={cx(
								`absolute top-full left-0 w-full z-40 bg-primary-slate-100 text-primary-slate drop-shadow-md rounded-lg overflow-hidden cursor-pointer`,
								{
									block: categoryOpen,
									hidden: !categoryOpen,
								}
							)}
						>
							{categories.length > 0
								? categories.map(({ node: { categoryName } }) => {
										return (
											<span
												key={categoryName}
												onClick={() => handleCategory(categoryName)}
												className={cx(
													`block py-3 px-4 hover:text-primary-slate-100 hover:bg-primary-slate-300 cursor-pointer bg-primary-slate-100`,
													{
														'bg-primary-slate-300 !text-primary-slate-100':
															filters?.categories.length > 0 &&
															filters?.categories?.includes(categoryName),
													}
												)}
											>
												{categoryName}
											</span>
										)
								  })
								: null}
						</div>
					</div>
					<div>
						<Button.Core
							onClick={() => setFilters({ keywords: ``, categories: [] })}
							className="bg-gradient-coral mx-auto !text-white"
						>
							Reset
						</Button.Core>
					</div>
				</div>
			</Container>
		</div>
	)
}
