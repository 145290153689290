import Blades from '@/components/BladeResolver'
import { BrandLine } from '@/components/UI/BrandLine'
import { NewsFilters } from '@/components/UI/NewsFilters'
import { useMenuState } from '@/context'
import { Meta } from '@/globals/Meta'
import { Layout } from '@/hoc/Layout'
import { WrapperProps } from '@/types/blocks'
import { graphql } from 'gatsby'
import { useEffect } from 'react'

const NewsPage = ({ data, location }) => {
	/**
	 * !READ
	 ** This page is built differently, just due to the nature and layout of the page *
	 ** It takes the first blade (which should be a Hero) and places it at the top *
	 ** Adds a filter inbetween that and the second blade, then does a full width HR *
	 ** and then uses blades as normal after. *
	 */
	const page = data?.page
	const posts = data?.posts
	const blades = page?.blades
	const seo = page?.metadata
	const categories = data?.categories
	const { setMenuOpen } = useMenuState()

	useEffect(() => {
		setMenuOpen(false)

		return () => setMenuOpen(false)
	}, [setMenuOpen])

	return (
		<Layout locale="en">
			<Meta {...{ seo }} />
			<Blades {...{ blades: blades.filter((b, i) => i === 0), posts }} />
			{categories.edges.length > 0 ? (
				<NewsFilters {...{ categories: categories.edges }} />
			) : null}
			<Blades {...{ blades: blades.filter((b, i) => i === 1), posts }} />
			<BrandLine fullWidth className="!my-0" />
			<Blades {...{ blades: blades.filter((b, i) => i > 1), posts }} />
		</Layout>
	)
}

export default NewsPage

export const query = graphql`
	query NewsPage {
		posts: allDatoCmsNews(
			sort: { fields: meta___firstPublishedAt, order: DESC }
		) {
			edges {
				node {
					id
					slug
					title
					excerpt
					cardDisplayImage {
						gatsbyImageData(placeholder: BLURRED)
						alt
						focalPoint {
							x
							y
						}
					}
					category {
						categoryName
						categoryColour
					}
					meta {
						firstPublishedAt(formatString: "DD MMMM, YYYY")
					}
				}
			}
		}
		page: datoCmsPage(slug: { eq: "news" }) {
			__typename
			id
			slug
			pageName
			metadata {
				description
				title
				twitterCard
				image {
					url
				}
			}
			blades {
				... on DatoCmsHero {
					...Hero
				}
				... on DatoCmsWrapper {
					...Wrapper
				}
			}
		}
		categories: allDatoCmsNewsCategory {
			edges {
				node {
					categoryName
					categoryColour
				}
			}
		}
	}
`
